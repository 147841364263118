import React, { useReducer, useEffect, useState, useContext } from 'react';
import { io } from 'socket.io-client';
import axios from 'axios'
import HelmetStyle from '../components/HelmetStyle.js'
import { convertMilliseconds } from "../../../components/Functions.js"
import { useGlobalState } from '../../../hooks/useCustomization.js';
import { domainConfig } from "../../../assets/config.js"

let socket
const AppContext = React.createContext();

const reducer = (state, action) => {
  switch (action.type) {
    case 'CONNECTED':
      return {
        ...state,
        isConnected: action.payload,
      };
    case 'setContexto':
      return {
        ...state,
        contexto: action.payload,
      }
    case 'resetUserHistory':
      return {
        ...state,
        userHistory: {},
      }
    case 'setHistory':
      return {
        ...state,
        history: action.payload,
      }
    case 'setParticipants':
      return {
        ...state,
        participants: action.payload,
      }
    default:
      return state;
  }
};

const GameProvider = ({ children }) => {
  const { dataApplication } = useGlobalState();
  const [appState, dispatch] = useReducer(reducer, dataApplication.data);
  const [score, setScore] = useState(false)
  const [start, setStart] = useState(false)
  const [isPlaying, setIsPlaying] = useState(true)
  const [currentPlayer, setCurrentPlayer] = useState(1);
  const [definitions, setDefinitions] = useState({ players: 1 });
  const [resetGame, setResetGame] = useState(0)
  const initialScore = {
    time: 0,
    player1: { 'value': 0 },
    player2: { 'value': 0 }
  }

  //Presenter states
  const [showPresentation, setShowPresentation] = useState(false)

  const getHistory = async () => {
    try {
      const response = await axios.get(
        `/api/eventos/history/${dataApplication.evento_id}?customizacao_id=${appState.customizacao_id}`,
        { customizacao_id: appState.customizacao_id }
      );
      let history = response.data.message
      history.forEach(historyItem => {
        historyItem.timeX = historyItem.time
        let convertedTime = convertMilliseconds(historyItem.time);
        convertedTime = convertedTime['minutes'] + ':' + convertedTime['seconds'] + ':' + convertedTime['milliseconds']
        historyItem.time = convertedTime
        historyItem.score = historyItem.player1.value + historyItem.player2.value
      });
      let data = sortRanking(history)
      dispatch({ type: 'setHistory', payload: data });
      return data; // Return the processed data
    } catch (error) {
      console.log(error);
      throw error; // Throw error to handle it outside
    }
  };

  useEffect(() => {
    if (dataApplication && dataApplication.presenter) {
      socket = io(domainConfig.socketAPI + '?evento_id=' + dataApplication.evento_id + '&aplicativo_id=' + appState.aplicativo_id + '&customizacao_id=' + appState.customizacao_id, {
        autoConnect: true,
      });
      socket.on('connect', () => {
        console.log('connect')
        dispatch({ type: 'CONNECTED', payload: true });
      });
      socket.on('login', (result) => {
        console.log('login')
        dispatch({ type: 'setContexto', payload: result });
      });
      socket.on('selectIndex', (result) => {
        dispatch({ type: 'setIndex', payload: result });
      });
      socket.on('disconnect', () => {
        console.log('disconnect')
        dispatch({ type: 'CONNECTED', payload: false });
      });
      socket.on('getData', (result) => {
        let data = processParticipants(result.participants, result.history)
        dispatch({ type: 'setHistory', payload: data.history });

      });
      socket.emit('login');

      return () => {
        if (socket) {
          socket.disconnect();
        }
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (appState.userHistory && Object.keys(appState.userHistory).length > 0) {
      setScore({
        time: appState.userHistory.time,
        player1: { 'value': appState.userHistory.player1.value, },
        player2: { 'value': appState.userHistory.player2.value }
      })
      setStart(5)
    } else {
      setScore(initialScore)
      setStart(appState.memoryGame.showVideoStart.status ? 0 : appState.memoryGame.allowTwoPlayers.status ? 1 : appState.memoryGame.showCountdown ? 2 : 3)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (resetGame > 0) {
      setScore(initialScore)
      setDefinitions({ players: 1 })
      setStart(appState.memoryGame.showVideoStart.status ? 0 : appState.memoryGame.allowTwoPlayers.status ? 1 : appState.memoryGame.showCountdown ? 2 : 3)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resetGame])

  return (
    <AppContext.Provider value={{
      appState, dispatch, score, setScore, start, setStart, showPresentation, initialScore,
      setShowPresentation, isPlaying, setIsPlaying, getHistory, definitions, setDefinitions,
      setResetGame, currentPlayer, setCurrentPlayer
    }}>
      <HelmetStyle appState={appState} />
      {children}
    </AppContext.Provider>
  );
};

const sortRanking = (data) => {
  data.sort((a, b) => {
    const scoreA = a.score || 0;
    const scoreB = b.score || 0;
    const timeA = a.timeX || Infinity; // Set default to Infinity instead of 0
    const timeB = b.timeX || Infinity; // Set default to Infinity instead of 0
    if (scoreA !== scoreB) {
      return scoreB - scoreA;
    }
    if (timeA !== timeB) {
      return timeA - timeB;
    }
    return 0
  });
  return data
}

const getData = () => {
  socket.emit('getData');
};

export {
  AppContext,
  GameProvider,
  getData
};

export const useAppState = () => {
  const context = useContext(AppContext);
  if (!context) {
    throw new Error('useAppState must be used within a GlobalStateProvider');
  }
  return context;
};

function processParticipants(participants, history) {
  participants.forEach(participant => {
    history.forEach(historyItem => {
      if (participant.visitante_id === historyItem.visitante_id) {
        historyItem.participantDetails = JSON.parse(participant.json);
        Object.assign(historyItem, JSON.parse(historyItem.json));
        historyItem.timeX = historyItem.time
        let convertedTime = convertMilliseconds(historyItem.time);
        convertedTime = convertedTime['minutes'] + ':' + convertedTime['seconds'] + ':' + convertedTime['milliseconds']
        historyItem.time = convertedTime
        historyItem.score = historyItem.player1.value + historyItem.player2.value
      }
    });
  });
  let sortedParticipants = sortRanking(history)
  return { 'history': sortedParticipants };
}