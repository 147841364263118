import { Helmet } from 'react-helmet-async';
import { useGlobalState } from '../hooks/useCustomization.js';
import { domainConfig } from "../assets/config.js";
import { isTransparent,convertToRem } from './Functions'

const HelmetStyle = () => {
  const { dataApplication, error } = useGlobalState();

  if (!error) {
    return (
      <Helmet>
        <style>
          {`
          html {
            --font: ${dataApplication.customizacao.fontFamily ? `'${dataApplication.customizacao.fontFamily}'` : "'Poppins', 'Segoe UI', 'Helvetica Neue', 'Arial', sans-serif"};
            --transparency: ${dataApplication.customizacao.transparency ? dataApplication.customizacao.transparency : "rgba(0,0,0,0.1)"};
            --transparencyPresenter: ${dataApplication.customizacao.transparency && !isTransparent(dataApplication.customizacao.transparency) ? dataApplication.customizacao.transparency : "rgba(0,0,0,0.04)"};
            --navBackground: ${dataApplication.customizacao.transparency ? dataApplication.customizacao.transparency : "rgba(255,255,255,1)"};
            --background: ${dataApplication.customizacao.background ? dataApplication.customizacao.background : "rgba(255,255,255,1)"};
            --primaryColor: ${dataApplication.customizacao.primaryColor ? dataApplication.customizacao.primaryColor : "rgba(255,255,255,1)"};
            --primaryColorText: ${dataApplication.customizacao.primaryColorText ? dataApplication.customizacao.primaryColorText : "#4ba700"};
            --baseColorText: ${dataApplication.customizacao.baseColorText ? dataApplication.customizacao.baseColorText : "rgba(255,255,255,1)"};
            --boxShadow: ${dataApplication.customizacao.boxShadow ? dataApplication.customizacao.boxShadow : "rgba(0, 0, 0, .15)"};
            --paddingBox: ${dataApplication.customizacao.paddingBox ? convertToRem(dataApplication.customizacao.paddingBox) : "1.5rem"};
            --h5: ${dataApplication.customizacao.h5Size ? dataApplication.customizacao.h5Size + 'rem' : "1.25rem"};
            --p: ${dataApplication.customizacao.pSize ? dataApplication.customizacao.pSize + 'rem' : "1rem"};
            --logoWidth: ${dataApplication.customizacao.logoWidth ? dataApplication.customizacao.logoWidth + 'px' : "300px"};
            --baseColorText: ${dataApplication.customizacao.baseColorText ? dataApplication.customizacao.baseColorText : "rgba(0,0,0,1)"};
            --boxShadow: ${dataApplication.customizacao.boxShadow ? dataApplication.customizacao.boxShadow : "rgba(0, 0, 0, .15)"}!important;
            --incorrectAnswer: ${dataApplication.customizacao.incorrectAnswer ? dataApplication.customizacao.incorrectAnswer : "cf000f"};
            --correctAnswer: ${dataApplication.customizacao.correctAnswer ? dataApplication.customizacao.correctAnswer : "#009944"};
            --invalidFeedback: ${dataApplication.customizacao.invalidFeedback ? dataApplication.customizacao.invalidFeedback : "#dc3545"};
            --bs-border-color: ${dataApplication.customizacao.bsBorderColor ? dataApplication.customizacao.bsBorderColor : "#ced4da"}!important;
            --mainLayoutJustifyContent: ${dataApplication.customizacao.mainLayout?.justifyContent ? dataApplication.customizacao.mainLayout.justifyContent : "center"};
            --mainLayoutPaddingBox: ${dataApplication.customizacao.mainLayout?.paddingBox ? convertToRem(dataApplication.customizacao.mainLayout.paddingBox) : "1.5rem"};
          }
        `}
        </style>
        {dataApplication.customizacao.background.type === 'image' ? (
          <style>
            {`
              html {
                --background: url('${domainConfig.imageServer + "/evento/" + dataApplication.evento_id + "/" + dataApplication.customizacao.background.url}');
              }
            `}
          </style>
        ) : ("")}
        {dataApplication.customizacao.background.type === 'color' ? (
          <style>
            {`
              html {
                --background: ${dataApplication.customizacao.background.color};
              }
            `}
          </style>
        ) : ("")}
      </Helmet>
    )

  }
};

export default HelmetStyle;