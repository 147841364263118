import { useState, useEffect, useRef } from "react";
import { useAppState } from '../context/AppContext.js'
import { motion } from 'framer-motion'
import { domainConfig } from "../../../assets/config.js"

const VideoStart = () => {
    const { appState, setStart } = useAppState();
    const [videoEnded, setVideoEnded] = useState(false);
    const [showControls, setShowControls] = useState(false);
    const videoRef = useRef(null);

    useEffect(() => {
        if (videoRef.current) {
            videoRef.current.play().catch((error) => {
                setShowControls(true);
            });
        }
    }, []);

    return (
        <>
            <video
                ref={videoRef}
                autoPlay
                playsInline
                controls={showControls}
                src={`${domainConfig.imageServer}/customizacao/${appState.customizacao_id}/${appState.memoryGame.showVideoStart.media}`}
                className="w-100"
                style={{ height: appState.memoryGame.showVideoStart.height }}
                alt="videocam"
                onEnded={() => setVideoEnded(true)}
            />
            <div>
                {videoEnded && (
                    <motion.div
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        exit={{ opacity: 0 }} className='default-box' key={'loaderDiv'}>
                        <button
                            className="primaryColor primaryColorText btn mt-3 mx-2"
                            onClick={() => setStart(appState.memoryGame.allowTwoPlayers.status ? 1 : 2)}
                        >
                            {appState.memoryGame.showVideoStart.buttonText}
                        </button>
                    </motion.div>
                )}
            </div>
        </>
    );
};

export default VideoStart;
