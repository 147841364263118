import React, { useState, useEffect, useCallback, useRef } from 'react'
import { Tabs, Tab, Row } from 'react-bootstrap'
import { AnimatePresence, LayoutGroup, motion } from "framer-motion"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import QRCode from "react-qr-code";
import {
    faArrowLeft
} from "@fortawesome/free-solid-svg-icons";
import { NavbarPresenter } from './components/Navbar.js'
import { useGlobalState } from '../../hooks/useCustomization.js';
import { domainConfig } from "../../assets/config.js"
import { useAppState, getData } from "./context/AppContext.js"
import { PageLoader } from '../../components/Elements.js'
import { TextWithLineBreaks } from "../../components/Functions.js"
import './assets/style.scss'

const GalleryBoardAdmin = () => {
    const { dataApplication, handleExit, blockScreen } = useGlobalState();
    const { appState, updateData } = useAppState();

    useEffect(() => {
        if (appState.isConnected) {
            getData()
            const intervalId = setInterval(() => {
                getData()
            }, 15000);
            return () => clearInterval(intervalId);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [updateData, appState.isConnected]);

    if (appState.participants) {
        if (!blockScreen) {
            return (
                <>
                    <motion.nav
                        className="position-fixed w-100 px-3 justify-content-between navbar navbar-expand"
                        key={'appsNavbar'}
                        initial={{ y: -100 }}
                        animate={{ y: 0 }}
                        transition={{ duration: 0.5 }}
                        exit={{ y: -100 }}>
                        <NavbarPresenter />
                    </motion.nav>
                    <motion.div className='presenter-box container' key="mainDiv" initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
                        <div className="box">
                            <div className="logoBox">
                                <div>
                                    <div className="backButton" onClick={() => handleExit()}><FontAwesomeIcon icon={faArrowLeft} size={'lg'} /></div>
                                </div>
                                <div className="logoBoxChildMiddle">
                                    <div className="pb-3">
                                        <h5 className="mb-1"><TextWithLineBreaks text={appState.titulo}></TextWithLineBreaks></h5>
                                        <span className="badge primaryColor primaryColorText mb-5 fw-normal">{appState.nomeAplicativo}</span>
                                    </div>
                                </div>
                                <div className="logoBoxHideButton">
                                    <div className="backButton"></div>
                                </div>
                            </div>
                            <Tabs
                                defaultActiveKey="k1"
                                transition={true}
                                id="noanim-tab-example"
                                className="mb-4"
                            >
                                <Tab eventKey="k1" title={`Configurações`}>
                                    <div className="row mx-0">
                                        <p className="my-5 text-center opacity-75">Nenhuma configuração disponível</p>
                                    </div>
                                </Tab>
                            </Tabs>
                        </div>
                    </motion.div >
                </>
            )
        } else {
            return (
                <div className='default-box'>
                    <div className={`box-full`}>
                        <img src={domainConfig.imageServer + "/evento/" + dataApplication.evento_id + "/" + dataApplication.customizacao.logo} alt="Logo" className="App-logo"></img>
                        <p>Essa tela só está disponível<br></br>para dispositivos maiores que 600px</p>
                    </div>
                </div>
            )
        }
    } else {
        return (
            <motion.div className='default-box' key={'loaderDiv'}>
                <PageLoader color={dataApplication.customizacao.primaryColor} width={75}></PageLoader>
            </motion.div>
        )
    }
}

const Gallery = ({ images, columns, rows, imageWidth, imageHeight, padding }) => {
    const { appState } = useAppState();

    return (
        <motion.div
            layout // ✅ Enables smooth reordering
            className="gallery-container"
            style={{
                alignContent: 'center',
                display: 'grid',
                gridTemplateColumns: `repeat(${columns}, ${imageWidth}px)`,
                gridTemplateRows: `repeat(${rows}, ${imageHeight}px)`,
                gap: padding + 'rem',
                gridAutoFlow: "row",
                position: "relative"
            }}
        >
            <LayoutGroup>
                <AnimatePresence>
                    {images.map((image) => (
                        <motion.div
                            layout
                            shouldReduceMotion
                            key={image.media} // ✅ Unique key
                            className="gallery-item"
                            initial={{ opacity: 0, scale: 0.8 }}
                            animate={{
                                opacity: 1,
                                scale: 1
                            }}
                            exit={{ opacity: 0 }} // ✅ Prevents jumps when removing
                        >
                            <motion.img
                                initial={{ opacity: 0 }}
                                animate={{
                                    opacity: 1,
                                    y: ["0%", "-3%", "3%", "0%"]
                                }}
                                transition={{
                                    opacity: {
                                        delay: (appState.apresentation.configs.newImageTotalTime / 1000) + 0.5
                                    },
                                    y: {
                                        duration: 4,
                                        ease: "easeInOut",
                                        repeat: Infinity
                                    }
                                }}
                                src={image.media}
                                alt="Gallery Image"
                                style={{
                                    width: `${imageWidth}px`,
                                    height: `${imageHeight}px`,
                                    objectFit: "cover",
                                }}
                            />
                        </motion.div>
                    ))}
                </AnimatePresence>
            </LayoutGroup>
        </motion.div>
    );
};

export const GalleryBoardModal = () => {
    const { appState, setShowPresentation } = useAppState();
    const [mediaItems, setMediaItems] = useState([]);
    const [showCurrentMedia, setShowCurrentMedia] = useState(false);
    const { dataApplication } = useGlobalState();
    const [currentMedia, setCurrentMedia] = useState({});
    const mediaItemsRef = useRef(mediaItems);
    const addedMedia = useRef(new Set()); // Track failed media
    const failedMedia = useRef(new Set()); // Track failed media
    const retriedMedia = useRef(new Set()); // Track retried media
    const nextReplaceIndex = useRef(0); // Track the next index to replace
    const isUpdating = useRef(false);
    const [forceUpdateKey, setForceUpdateKey] = useState(0);
    const resultsRef = useRef(appState.results);

    // Update the ref whenever mediaItems change
    useEffect(() => {
        mediaItemsRef.current = mediaItems;
    }, [mediaItems]);

    const preloadImages = (url) => {
        return new Promise((resolve, reject) => {
            const img = new Image();
            img.src = url;
            img.onload = () => resolve(url);
            img.onerror = () => {
                console.error(`Failed to load image: ${url}`);
                resolve(null);
            };
        });
    };

    const updateMediaItems = useCallback(async () => {

        if (isUpdating.current) return;

        isUpdating.current = true;
        try {
            const isInArray = (obj1, arr) => arr.some((obj2) => obj1.resultado_id === obj2.resultado_id);
            const newElements = resultsRef.current.filter((obj1) => {
                const notInMediaItems = !isInArray(obj1, mediaItemsRef.current);
                const notInAddedMedia = !addedMedia.current.has(obj1.resultado_id);
                const notFailedOrRetried = !failedMedia.current.has(obj1.resultado_id) || !retriedMedia.current.has(obj1.resultado_id);
                return notInMediaItems && notInAddedMedia && notFailedOrRetried;
            });

            let newParticipant = {}
            if (newElements[0]) {
                newParticipant = newElements[0];
            } else {
                const candidateIds = Array.from(addedMedia.current).filter((id) => !mediaItemsRef.current.some((item) => item.resultado_id === id));

                if (candidateIds.length > 0) {
                    const randomId =
                        candidateIds[
                        Math.floor(Math.random() * candidateIds.length)
                        ];
                    // Retrieve the full candidate object from your results.
                    newParticipant = resultsRef.current.find(
                        (item) => item.resultado_id === randomId
                    );

                }
            }

            if (newParticipant.media) {
                const preloadedMedia = await preloadImages(newParticipant.media);
                if (preloadedMedia) {

                    addedMedia.current.add(newParticipant.resultado_id);
                    const addNewItem = () => {

                        setMediaItems((prevState) => {
                            const maxItems = appState.apresentation.configs.columns * appState.apresentation.configs.rows;
                            const updatedItems = [...prevState];

                            // If the gallery is already full, set state immediately
                            if (updatedItems.length >= maxItems) {
                                updatedItems.pop();
                            }

                            return updatedItems; // No changes yet, wait for timeout
                        });

                        let timeout = 0
                        if ((appState.apresentation.configs.columns * appState.apresentation.configs.rows) < addedMedia.current.size) {
                            timeout = 500
                        }
                        setTimeout(() => {
                            setMediaItems((prevState) => {
                                const maxItems = appState.apresentation.configs.columns * appState.apresentation.configs.rows;
                                const updatedItems = [...prevState];

                                // If the gallery is already full, set state immediately
                                if (updatedItems.length >= maxItems) {
                                    updatedItems.pop();
                                }

                                updatedItems.unshift(newParticipant);
                                return updatedItems; // No changes yet, wait for timeout
                            });
                            setCurrentMedia(newParticipant);
                            setShowCurrentMedia(true);

                            setTimeout(() => {
                                setShowCurrentMedia(false);
                                setTimeout(() => {
                                    isUpdating.current = false;
                                }, 500);
                            }, appState.apresentation.configs.newImageTotalTime);
                        }, timeout);
                    };
                    addNewItem();
                } else {
                    if (!failedMedia.current.has(newParticipant.resultado_id)) {
                        failedMedia.current.add(newParticipant.resultado_id);
                    } else {
                        retriedMedia.current.add(newParticipant.resultado_id);
                        setForceUpdateKey((prevKey) => prevKey + 1);
                    }
                    isUpdating.current = false;
                }
            } else {
                isUpdating.current = false;
            }
        } catch (error) {
            isUpdating.current = false;
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [forceUpdateKey]);

    useEffect(() => {
        updateMediaItems();
        const interval = setInterval(updateMediaItems, appState.apresentation.configs.transitionTime);
        return () => clearInterval(interval);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [updateMediaItems]);

    useEffect(() => {
        const handleKeyDown = (event) => {
            if (event.key === 'Escape' || event.keyCode === 27) {
                setShowPresentation(false);
            }
        };
        document.addEventListener('keydown', handleKeyDown);
        return () => {
            document.removeEventListener('keydown', handleKeyDown);
        };
    }, [setShowPresentation]);

    useEffect(() => {
        resultsRef.current = appState.results;
    }, [appState.results]);

    return (
        <>
            {appState.apresentation.showQRCode && appState.apresentation.showQRCode.status && (
                <div className="modalQRCodeShare">
                    <div>
                        {appState.apresentation.showQRCode.text !== '' && <p className="mb-2">{appState.apresentation.showQRCode.text}</p>}
                        <QRCode
                            className="bg-white p-3 qrcodeContainer"
                            value={
                                appState.apresentation.showQRCode.link !== ''
                                    ? appState.apresentation.showQRCode.link
                                    : domainConfig.aplicativos + '/?token=' + dataApplication.token
                            }
                        />
                    </div>
                </div>
            )}

            <AnimatePresence>
                {showCurrentMedia && (
                    <motion.div
                        className="current-media-overlay"
                        style={{
                            position: 'absolute',
                            top: `15px`,
                            left: `15px`,
                            zIndex: 9999,
                        }}
                        initial={{ scale: 0, opacity: 0 }}
                        animate={{ scale: 1, opacity: 1 }}
                        exit={{ scale: 0, opacity: 0 }}
                        transition={{ duration: 0.5 }}
                    >
                        <img
                            src={currentMedia.media}
                            alt="Current Media"
                            style={{
                                width: `${appState.apresentation.configs.newImageWidth}px`,
                                height: `${appState.apresentation.configs.newImageHeight}px`,
                                objectFit: 'cover',
                            }}
                        />
                    </motion.div>
                )}
            </AnimatePresence>
            <div className="logoBox">
                <div className="logoBoxChildMiddle">
                    <img
                        src={domainConfig.imageServer + "/evento/" + dataApplication.evento_id + "/" + dataApplication.customizacao.logo}
                        alt="Logo"
                        className="App-logo pb-0"
                    ></img>
                    {appState.apresentation.title.status && (
                        <h5 className="my-5">
                            <TextWithLineBreaks text={appState.apresentation.title.value}></TextWithLineBreaks>
                        </h5>
                    )}
                </div>
            </div>
            <Gallery
                images={mediaItems}
                columns={appState.apresentation.configs.columns}
                rows={appState.apresentation.configs.rows}
                imageWidth={appState.apresentation.configs.width}
                imageHeight={appState.apresentation.configs.height}
                padding={appState.apresentation.configs.padding}
                showCurrentMedia={showCurrentMedia}
            />
        </>
    );
};

export default GalleryBoardAdmin;
