import { useEffect, useRef, useContext } from 'react'
import { motion, AnimatePresence } from 'framer-motion'
import { differenceInMilliseconds } from "date-fns";
import { domainConfig } from "../../../assets/config.js"
import axios from 'axios'
import Countdown from "./Countdown.js"
import { useGlobalState } from '../../../hooks/useCustomization.js';
import YouTubeEmbed from "../../../components/YoutubeEmbed.js";
import AudioEmbed from "../../../components/AudioEmbed.js";
import { TextWithLineBreaks } from "../../../components/Functions.js"
import NotyfContext from "../../../contexts/NotyfContext.js";
import { useAppState } from '../context/AppContext.js';
import correctAnswer from "../../../assets/sounds/11334109_correct-answer_by_lucafrancini_preview.mp3"
import wrongAnswer from "../../../assets/sounds/6786335_wrong-4_by_lucafrancini_preview.mp3"
import noAnswer from "../../../assets/sounds/11337946_defeat_by_lucafrancini_preview.mp3"

const Exercises = () => {
    const { dataApplication } = useGlobalState();
    const { appState, setScore, setStart, score, setShowAns, setDateUnix, loadingRef, isPlaying, dateUnix, loadingQuestion, handleNextStep, setLoadingQuestion } = useAppState();
    const audioCorrect = useRef(new Audio(correctAnswer));
    const audioWrong = useRef(new Audio(wrongAnswer));
    const audioNoAnswer = useRef(new Audio(noAnswer));
    const notyf = useContext(NotyfContext);

    useEffect(() => {
        if (!isPlaying) {
            audioCorrect.current.volume = 0;
            audioWrong.current.volume = 0;
            audioNoAnswer.current.volume = 0;
        } else {
            audioCorrect.current.volume = 0.7;
            audioWrong.current.volume = 0.7;
            audioNoAnswer.current.volume = 0.7;
        }
    }, [isPlaying])

    //Handler for score and set up next question handler
    const handleAnswerClick = (isCorrect, value, orderNumber, dateUnix) => {
        if (loadingRef.current === false) {
            setLoadingQuestion(true)
            loadingRef.current = true
            setShowAns(orderNumber)
            const buttons = document.querySelectorAll('button');
            buttons.forEach(button => button.blur());
            if (appState.answerFeedback && appState.sound) {
                if (orderNumber === null) {
                    audioNoAnswer.current.play();
                } else {
                    if (isCorrect) {
                        audioCorrect.current.play();
                    } else {
                        audioWrong.current.play();
                    }
                }
            }
            let newDate = new Date()
            let milliseconds = differenceInMilliseconds(newDate, dateUnix)
            let newScore = JSON.parse(JSON.stringify(score))
            newScore.time = newScore.time + milliseconds
            newScore.value = isCorrect ? newScore.value + parseInt(value) : newScore.value
            newScore.totalCorrect = isCorrect ? newScore.totalCorrect + parseInt(1) : newScore.totalCorrect
            newScore.answers[newScore.currentIndex] = { order: orderNumber, time: milliseconds, value: value, question_id: appState.questionsPrepared[newScore.currentIndex].question_id, exercise: appState.questionsPrepared[newScore.currentIndex].exercise }
            newScore.currentIndex = newScore.currentIndex + parseInt(1)
            setScore(prevState => ({
                ...prevState,
                answers: newScore.answers,
                time: newScore.time,
                value: newScore.value,
                totalCorrect: newScore.totalCorrect
            }));
            setTimeout(() => {
                axios.post(`/api/eventos/history/`, {
                    visitante_id: dataApplication.visitante.visitante_id,
                    evento_id: dataApplication.evento_id,
                    customizacao_id: appState.customizacao_id,
                    json: JSON.stringify(newScore),
                }).then(function (response) {
                    if (appState.showChart) {
                        setStart(4)
                    } else if (appState.showRanking) {
                        setStart(3)
                    } else if (appState.questionsPrepared[newScore.currentIndex - 1]?.additionalAnswer?.status && appState.questionsPrepared[newScore.currentIndex - 1].exercise === 1) {
                        setStart(5)
                    } else {
                        if (newScore.currentIndex >= appState.questionsPrepared.length) {
                            let result = false
                            if (appState.result && appState.result.length > 0) {
                                appState.questionsPrepared.forEach(element => {
                                    if (element.exercise === 3) {
                                        result = true
                                    }
                                });
                            }
                            setDateUnix(new Date())
                            setShowAns(false)
                            setLoadingQuestion(false)
                            loadingRef.current = false
                            setStart(result ? 2 : 6)
                        } else {
                            handleNextStep()
                        }
                    }
                }).catch(function (error) {
                    setDateUnix(new Date())
                    setShowAns(false)
                    setLoadingQuestion(false)
                    loadingRef.current = false
                    notyf.open({
                        type: "danger",
                        message: 'Houve um erro, tente novamente',
                        ripple: true,
                        dismissible: true,
                    });
                })
            }, 1500)
        }
    }

    if (appState.questionsPrepared[score.currentIndex]?.question_id) {
        return (
            <AnimatePresence mode="wait">
                <motion.div
                    key={appState.questionsPrepared[score.currentIndex].question_id}
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}>
                    <div className='col-md-12 px-sm-4'>
                        {(appState.timer.status) ? (
                            <Countdown appState={appState} loadingRef={loadingRef} dateUnix={dateUnix} isPlaying={isPlaying} handleAnswerClick={handleAnswerClick} />
                        ) : ("")}
                        <h5 className="questionTitle mb-0"><TextWithLineBreaks text={appState.questionsPrepared[score.currentIndex].question}></TextWithLineBreaks></h5>
                        <span className="badge primaryColor primaryColorText my-3">{score.currentIndex + 1} de {appState.questionsPrepared.length}</span>
                    </div>
                    {(() => {
                        switch (appState.questionsPrepared[score.currentIndex].type) {
                            case 2:
                                return <img
                                    className={"img-thumbnail"}
                                    src={
                                        domainConfig.imageServer +
                                        "/customizacao/" +
                                        appState.customizacao_id +
                                        "/" +
                                        appState.questionsPrepared[score.currentIndex].url
                                    }
                                    alt=""
                                />
                            case 3:
                                return <YouTubeEmbed
                                    videoId={appState.questionsPrepared[score.currentIndex].url}
                                />
                            case 4:
                                return <AudioEmbed audioId={appState.questionsPrepared[score.currentIndex].url} customizacao_id={appState.customizacao_id} />
                            default:
                                return ""
                        }
                    })()}
                    <input type="hidden" id="question_id" value={appState.questionsPrepared[score.currentIndex].question_id}></input>
                    <div className="row justify-content-center">
                        <AnimatePresence>
                            {appState.questionsPrepared[score.currentIndex].answers.map((answer, key) => {
                                let points = 0
                                if (answer.isCorrect) {
                                    points = appState.questionsPrepared[score.currentIndex].value
                                } else {
                                    points = 0
                                }
                                return (
                                    <ExerciseCards key={'ExerciseCards' + key} index={key} points={points} answer={answer} handleAnswerClick={handleAnswerClick}></ExerciseCards>
                                )
                            })}
                        </AnimatePresence>
                    </div>
                </motion.div>
            </AnimatePresence>
        )
    }
}

const ExerciseCards = ({ index, answer, points, handleAnswerClick }) => {
    const { appState, score, showAns, dateUnix, loadingQuestion, setLoadingQuestion } = useAppState();

    const shakeAnimation = {
        scale: [1, 1.03, 1], // Scale up and then back down
        transition: {
            duration: 1.25, // Duration of one beat
        },
        opacity: 1,
    };

    // Define the normal animation
    const normalAnimation = {
        opacity: 1,
        transition: { duration: 0.5, delay: '0.' + (index * 2).toString() }
    };

    let className = "";
    switch (appState.questionsPrepared[score.currentIndex].exercise) {
        case 1:
            className += (showAns === answer.order && answer.isCorrect && appState.answerFeedback ? ' correctAnswer' : '');
            className += (showAns === answer.order && !answer.isCorrect && appState.answerFeedback ? ' incorrectAnswer' : '');
            className += (showAns !== false && answer.isCorrect && appState.correctFeedback ? ' correctAnswer' : '');
            break;
        case 2:
        case 3:
            answer.isCorrect = false
            points = 0
            break

        default:
            break;
    }

    if (answer.url && answer.url !== '') {
        return (
            <motion.div key={'option1' + index} className={`quizzButtonContainer col-md-${appState.questionsPrepared[score.currentIndex]['col']}`} initial={{ opacity: 0 }} animate={showAns === answer.order ? shakeAnimation : normalAnimation} exit={{ opacity: 0 }}>
                <img
                    className={`quizzButtonImage ${className}`}
                    src={
                        domainConfig.imageServer +
                        "/customizacao/" +
                        appState.customizacao_id +
                        "/" +
                        answer.url
                    }
                    onClick={(e) => handleAnswerClick(answer.isCorrect, points, answer.order, dateUnix)}
                    key={appState.customizacao_id + index + dateUnix}
                    disabled={loadingQuestion && showAns !== answer.order}
                    alt={answer.text}
                />
            </motion.div>
        );
    } else {
        return (
            <motion.div key={'option2' + index} className={`quizzButtonContainer col-md-${appState.questionsPrepared[score.currentIndex]['col']}`} initial={{ opacity: 0 }} animate={showAns === answer.order ? shakeAnimation : normalAnimation} exit={{ opacity: 0 }}>
                <button
                    className={`quizzButton btn primaryColor primaryColorText ${className}`}
                    onClick={(e) => handleAnswerClick(answer.isCorrect, points, answer.order, dateUnix)}
                    key={appState.customizacao_id + index + dateUnix}
                    disabled={loadingQuestion && showAns !== answer.order}>
                    <TextWithLineBreaks text={answer.text}></TextWithLineBreaks>
                </button>
            </motion.div>
        );
    }

};

export default Exercises;