export const domainConfig = {
  imageServer: process.env.REACT_APP_IMAGES,
  eventos: process.env.REACT_APP_PLATAFORMA_EVENTOS,
  eventosAPI: process.env.NODE_ENV === "development"
    ? "https://localhost:8080"
    : process.env.REACT_APP_PLATAFORMA_EVENTOS_RESTAPI,
  eventosSocketAPI: process.env.NODE_ENV === "development"
    ? "wss://localhost:4000"
    : process.env.REACT_APP_PLATAFORMA_EVENTOS_WEBSOCKETAPI,
  aplicativos: process.env.NODE_ENV === "development"
    ? "https://localhost:3000"
    : process.env.REACT_APP_PLATAFORMA_APLICATIVOS,
  aplicativosAPI: process.env.NODE_ENV === "development"
    ? "https://localhost:3000"
    : process.env.REACT_APP_PLATAFORMA_APLICATIVOS_API,
  restAPI: process.env.NODE_ENV === "development"
    ? "https://localhost:8080"
    : process.env.REACT_APP_RESTAPI,
  socketAPI: process.env.NODE_ENV === "development"
    ? "ws://localhost:4000"
    : process.env.REACT_APP_WEBSOCKETAPI,
}