import React, { useReducer, useContext, useState, useEffect, useRef } from 'react';
import { io } from 'socket.io-client';
import HelmetStyle from '../components/HelmetStyle.js'
import { useGlobalState } from '../../../hooks/useCustomization.js';
import { domainConfig } from "../../../assets/config.js"
import { convertDate } from "../../../components/Functions"

let socket

const AppContext = React.createContext();

const reducer = (state, action) => {
  switch (action.type) {
    case 'CONNECTED':
      return {
        ...state,
        isConnected: action.payload,
      }
    case 'setContexto':
      return {
        ...state,
        contexto: action.payload,
      }
    case 'setMessages':
      return {
        ...state,
        messages: action.payload,
      }
    case 'setParticipants':
      return {
        ...state,
        participants: action.payload,
      }
    case 'setIndex':
      return {
        ...state,
        contexto: {
          ...state.data,
          currentQuestion: action.payload
        },
      }
    default:
      return state;
  }
};

const GameProvider = ({ children }) => {
  const { dataApplication, setLoading } = useGlobalState();
  const [appState, dispatch] = useReducer(reducer, dataApplication.data);
  const [updateMessages, setUpdateMessages] = useState(0)
  const [showMessages, setShowMessages] = useState(0)
  const [showPresentation, setShowPresentation] = useState(false)
  const filtersRef = useRef('approved');

  const getQuestionIndex = () => {
    let id
    for (let i = 0; i < appState.messages.approved.length; i++) {
      if (appState.messages.approved[i].mensagem_id === appState.contexto.currentQuestion) {
        id = i
      }
    }
    return id
  }

  useEffect(() => {
    if (dataApplication && dataApplication.presenter) {
      socket = io(domainConfig.socketAPI + '?evento_id=' + dataApplication.evento_id + '&aplicativo_id=' + dataApplication.data.aplicativo_id + '&customizacao_id=' + dataApplication.data.customizacao_id, {
        autoConnect: true,
      });
      socket.on('connect', () => {
        console.log('connect')
        dispatch({ type: 'CONNECTED', payload: true });
      });
      socket.on('login', (result) => {
        dispatch({ type: 'setContexto', payload: result });
      });
      socket.on('selectIndex', (result) => {
        dispatch({ type: 'setIndex', payload: result });
      });
      socket.on('disconnect', () => {
        console.log('disconnect')
        dispatch({ type: 'CONNECTED', payload: false });
      });
      socket.on('updateData', () => {
        setUpdateMessages((prevState) => prevState + 1)
      });
      socket.on('getData', (result) => {
        let data = processParticipants(result.participants, result.messages, appState)
        data.messages = filterMessages(data.messages);
        setLoading(false)
        dispatch({ type: 'setMessages', payload: data.messages });
        dispatch({ type: 'setParticipants', payload: data.participants });
      });
      socket.emit('login');
      return () => {
        if (socket) {
          socket.disconnect();
          console.log('Socket disconnected');
        }
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <AppContext.Provider value={{
      appState, dispatch, showMessages, setShowMessages, showPresentation,
      filtersRef, updateMessages, setUpdateMessages, setShowPresentation, getQuestionIndex
    }}>
      <HelmetStyle appState={appState} />
      {children}
    </AppContext.Provider>
  );
};

const getData = () => {
  socket.emit('getData');
};

const updateData = () => {
  socket.emit('updateData');
};

const selectIndex = (id) => {
  socket.emit('selectIndex', id);
};

export {
  AppContext,
  GameProvider,
  selectIndex,
  updateData,
  getData
};

export const useAppState = () => {
  const context = useContext(AppContext);
  if (!context) {
    throw new Error('useAppState must be used within a GlobalStateProvider');
  }
  return context;
};

function processParticipants(participants, history) {
  participants.forEach(participant => {
    participant.participantDetails = JSON.parse(participant.json);
    const transformedDetails = {};
    participant.data_visitacao = convertDate(participant.data_visitacao)
    for (const key in participant.participantDetails) {
      const detail = participant.participantDetails[key];
      transformedDetails[detail.label] = detail.value;
    }
    Object.assign(participant, transformedDetails);
    participant.history = []

    history.forEach(historyItem => {
      if (participant.visitante_id === historyItem.visitante_id) {
        if (historyItem.json) {
          historyItem.json = JSON.parse(historyItem.json);
          participant.data = historyItem.data
        }
        historyItem.participantDetails = JSON.parse(participant.json);
        participant.history.push(historyItem);
      }
    });
  });

  return { 'participants': participants, 'messages': history };
}

function filterMessages(data) {
  // Sort the data in one go
  data.sort((a, b) => {
    if (a.favorito > b.favorito) return -1;
    if (a.favorito < b.favorito) return 1;
    if (a.lido < b.lido) return -1;
    if (a.lido > b.lido) return 1;
    if (a.data < b.data) return -1;
    if (a.data > b.data) return 1;
    return 0;
  });

  // Initialize arrays for each category
  const approvedMessages = [];
  const waitingMessages = [];
  const answeredMessages = [];
  const notApproved = [];

  // Single loop to categorize messages
  data.forEach(item => {
    if (item.aprovado === 1 && item.lido === 0) {
      approvedMessages.push(item);
    } else if (item.aprovado === 0 && item.lido === 0) {
      waitingMessages.push(item);
    } else if (item.aprovado === 1 && item.lido === 1) {
      answeredMessages.push(item);
    } else if (item.aprovado === 2) {
      notApproved.push(item);
    }
  });

  // Return the categorized messages
  return {
    all: data,
    approved: approvedMessages,
    waiting: waitingMessages,
    answered: answeredMessages,
    notApproved: notApproved
  };
}

