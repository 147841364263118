import { useEffect, useState, useContext, useRef } from 'react'
import axios from 'axios'
import { Tooltip, OverlayTrigger, InputGroup, Form, ListGroup } from 'react-bootstrap'
import QRCode from "react-qr-code";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCopy, faLayerGroup, faUsersLine } from '@fortawesome/free-solid-svg-icons';
import NotyfContext from "./contexts/NotyfContext.js";
import { TextWithLineBreaks, convertDate, handleShareLink, qrcodeDownload } from "./components/Functions.js"
import { useGlobalState } from './hooks/useCustomization.js';
import { domainConfig } from "./assets/config.js"
import BaseLayout from "./components/BaseLayout"

export const Applications = () => {
    const { dataApplication } = useGlobalState();

    return (
        <BaseLayout>
            <div className="mb-4">
                {dataApplication.customizacao.applications.title.status && <div className="col">
                    <h5 className="mb-3"><TextWithLineBreaks text={dataApplication.customizacao.applications.title.value}></TextWithLineBreaks></h5>
                </div>}
                {dataApplication.customizacao.applications.description.status && <div className="col">
                    <p className="mb-3"><TextWithLineBreaks text={dataApplication.customizacao.applications.description.value}></TextWithLineBreaks></p>
                </div>}
            </div>
            <div className="row">
                {dataApplication.apps.map((element, index) => {
                    return (
                        <DefaultCard dataApplication={dataApplication} element={element} key={"cardApp" + index} index={index}></DefaultCard>
                    )
                }
                )}
            </div>
        </BaseLayout>
    )
}

export const ApplicationsPresenter = () => {
    const { dataApplication, setApp, blockScreen } = useGlobalState();
    const [visitors, setVisitors] = useState({})
    const notyf = useContext(NotyfContext);

    useEffect(() => {
        const fetchDataVisitors = async () => {
            try {
                const responseP = await axios.get(`/api/eventos/visitors/${dataApplication.evento_id}`)
                setVisitors(responseP.data.message)
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };
        fetchDataVisitors();
        const intervalId = setInterval(() => {
            fetchDataVisitors()
        }, 15000);
        return () => clearInterval(intervalId);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    if (!blockScreen) {
        return (
            <>
                <div className='default-box'>
                    <div className="box-full">
                        <div className="logoBox justify-content-center">
                            <img src={domainConfig.imageServer + "/evento/" + dataApplication.evento_id + "/" + dataApplication.customizacao.logo} alt="Logo" className="App-logo"></img>
                        </div>
                        <div className="col"><h5>{dataApplication.titulo}</h5></div>
                        <div className="col mt-2"><span className='badge primaryColor primaryColorText mt-2 me-2 fw-normal'>{dataApplication.apps.length} aplicativos</span><span className='badge primaryColor primaryColorText mb-2 fw-normal'>{dataApplication.customizacao.form.status ? ('Registro em aberto') : ('Registro finalizado')}</span></div>
                        <div className="col"><p className="mb-0">{`${convertDate(dataApplication.data_criacao, 'dd/MM HH:mm')} até ${convertDate(dataApplication.data_termino, 'dd/MM HH:mm')}`}</p></div>
                    </div>
                </div>
                <div className="presenterSidebarParent">
                    <div className="presenterSidebar text-center">
                        <div className="presenterSidebarTop">
                            <h5>Modo Apresentador</h5>
                            <p className="mt-4">Convide os participantes para jogar acessando o link abaixo</p>
                            <InputGroup>
                                <Form.Control
                                    readOnly
                                    aria-label="Share Link Quizz"
                                    value={domainConfig.aplicativos + '/?token=' + dataApplication.token}
                                />
                                <OverlayTrigger
                                    placement={"left"}
                                    overlay={<Tooltip>Copiar link de acesso</Tooltip>}
                                >
                                    <button className="btn primaryColor primaryColorText" onClick={(() => handleShareLink(domainConfig.aplicativos + "/?token=" + dataApplication.token, notyf, dataApplication.customizacao.primaryColor))}>
                                        <FontAwesomeIcon icon={faCopy} />
                                    </button>
                                </OverlayTrigger>
                            </InputGroup>
                        </div>
                        <div className="presenterSidebarStart">
                            <button className="btn primaryColor pe-none primaryColorText">  <FontAwesomeIcon icon={faUsersLine} className="me-2" />{visitors.length > 0 ? visitors.length : 0}</button>
                        </div>
                        <div className="presenterSidebarBottom">
                            <div>
                                <h5 className="mb-3"><FontAwesomeIcon icon={faLayerGroup} className="me-2 primaryColorText" />Aplicativos</h5>
                                <ListGroup as="ul">
                                    {dataApplication.apps.map((element, index) => (
                                        <ListGroup.Item className="appList" key={'appList' + index} action onClick={() => setApp(index)}>
                                            <p className="mb-0 fs-14"><TextWithLineBreaks text={element.titulo}></TextWithLineBreaks></p>
                                            <span className="badge fw-normal">{element.nomeAplicativo}</span>
                                        </ListGroup.Item>
                                    ))}
                                    {dataApplication.apps.length === 0 && <small className="p mt-3 opacity-75">Nenhum aplicativo encontrado</small>}
                                </ListGroup>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    } else {
        return (
            <div className='default-box'>
                <div className={`box-full`}>
                    <img src={domainConfig.imageServer + "/evento/" + dataApplication.evento_id + "/" + dataApplication.customizacao.logo} alt="Logo" className="App-logo"></img>
                    <p>Essa tela só está disponível<br></br>para dispositivos maiores que 600px</p>
                </div>
            </div>
        )
    }

}

export const NoApplications = () => {
    const { dataApplication, handleExit } = useGlobalState();
    // eslint-disable-next-line no-unused-vars
    const [user, setUser] = useState(dataApplication.visitante)
    const qrCodeRef = useRef(null);

    const dataElements = Object.keys(user.json).map((key, index) => (
        <p key={key} className={index + 1 === Object.keys(user.json).length ? "mb-0" : "mb-1"}>{user.json[key].label}: <span className="opacity-75">{user.json[key].value}</span></p>
    ));

    return (
        <BaseLayout showBackButton={dataApplication.customizacao.form.reset ? true : false} backButtonHandler={() => handleExit()}>
            <div className="mb-4">
                {dataApplication.customizacao.end.title.status &&
                    <div className="col">
                        <h5 className="mb-3"><TextWithLineBreaks text={dataApplication.customizacao.end.title.value}></TextWithLineBreaks></h5>
                    </div>}
                {dataApplication.customizacao.end.description.status &&
                    <div className="col">
                        <p className="mb-3"><TextWithLineBreaks text={dataApplication.customizacao.end.description.value}></TextWithLineBreaks></p>
                    </div>}
                {dataApplication.customizacao.end.showUserData &&
                    <div className="dataElementsContainer my-4">
                        {dataElements}
                    </div>}
                {dataApplication.customizacao.end.showQRCode && <>
                    <QRCode ref={qrCodeRef} className="bg-white p-3 qrcodeContainer mb-3" value={domainConfig.imageServer + "/customizacao/" + dataApplication.evento_id + "/" + user.visitante_id} />
                    <div>
                        <button className="primaryColor primaryColorText btn mt-3" onClick={() => qrcodeDownload(qrCodeRef, dataApplication.titulo + '-qrcode')}>Baixar Código QR</button>
                    </div>
                </>}
            </div>
        </BaseLayout>
    )
}

const DefaultCard = ({ element, index }) => {
    const { setApp } = useGlobalState();
    return (
        <div className={`col-md-6 col-12 my-2`}>
            <div className={`cardContainer`} key={'cardContainer' + index}>
                <div className={`cardAplicativos`} onClick={() => setApp(index)} style={{ backgroundImage: element.thumbnail ? `url(${domainConfig.imageServer}/customizacao/${element.customizacao_id}/${element.thumbnail})` : '' }}>
                    <p className='mb-1 fs-14'><TextWithLineBreaks text={element.titulo}></TextWithLineBreaks></p>
                    <span className="badge primaryColor primaryColorText fw-normal">{element.nomeAplicativo}</span>
                </div>
            </div>
        </div>
    )
}